import { createContext, useContext } from 'react';
import { ClientModule } from '../models/ClientModule';

type ActivitiesContextType = {
  clientModule?: ClientModule | null;
  forCurrentUser?: boolean;
  hasContextMenu?: boolean;
  type: 'activities' | 'tasks' | 'documents';
  loading?: boolean;
  moduleSectionId?: string;
};

export const ActivitiesContext = createContext<ActivitiesContextType>({ type: 'activities' });
export const useActivites = (): ActivitiesContextType => useContext(ActivitiesContext);
